import React, { useContext, useCallback, useEffect } from 'react'
import { getFirebaseAuth } from '@babelcoder/gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import { GlobalStateContext } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import AuthResetPassword from '@components/auth/ResetPassword'
import authErrors from '@utils/auth-errors'

function AuthResetPasswordPage({ location: { state } }) {
  const { dispatch } = useContext(GlobalStateContext)
  const { code, email } = state || {}

  useEffect(() => {
    if (!code) {
      navigate('/', { replace: true })
    }
  }, [code])

  const onSubmit = useCallback(
    async (password) => {
      try {
        const firebaseAuth = await getFirebaseAuth()
        await firebaseAuth.confirmPasswordReset(code, password)
        navigate('/auth/complete', {
          replace: true,
          state: { type: 'reset-password' },
        })
      } catch (error) {
        let message =
          authErrors[error.code] ||
          'เกิดข้อผิดพลาดในการตั้งค่ารหัสผ่านใหม่ โปรดลองอีกครั้ง'

        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'danger',
            title: 'เกิดข้อผิดพลาด',
            message,
          },
        })
      }
    },
    [code, dispatch]
  )

  return code ? (
    <AuthResetPassword email={email} onSubmit={onSubmit}></AuthResetPassword>
  ) : (
    <LoadingImage></LoadingImage>
  )
}

export default AuthResetPasswordPage
