import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import AuthBackgroundImage from '@assets/images/forgot-password.svg'
import AuthLayout from './AuthLayout'
import Title from './Title'
import Form from './Form'
import Input from './Input'
import SubmitButton from './SubmitButton'

const SubTitle = styled.div`
  margin: ${({ theme }) => theme.spacers.normal} 0;
`

function ForgotPassword({ email, onSubmit }) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8, 'ความยาวขั้นต่ำ 8 ตัวอักษร')
        .required('โปรดระบุรหัสผ่าน'),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'โปรดระบุรหัสผ่านให้ตรงกัน'),
    }),
  })

  const submit = ({ password }) => onSubmit(password)

  return (
    <AuthLayout Image={AuthBackgroundImage}>
      <Title>ตั้งค่ารหัสผ่านใหม่</Title>
      <SubTitle>โปรดระบุรหัสผ่านใหม่ที่มีความยาวไม่ต่ำกว่า 8 ตัวอักษร</SubTitle>
      <Form onSubmit={handleSubmit(submit)}>
        <input
          readOnly
          type="text"
          name="username"
          autoComplete="username"
          value={email}
          className="d-none"
        />
        <Input
          type="password"
          name="password"
          ref={register}
          placeholder="Password"
          autoComplete="new-password"
          error={errors.password}
        />
        <Input
          type="password"
          name="passwordConfirmation"
          ref={register}
          placeholder="Confirm Password"
          autoComplete="new-password"
          error={errors['passwordConfirmation']}
        />
        <SubmitButton>ตั้งค่ารหัสผ่าน</SubmitButton>
      </Form>
    </AuthLayout>
  )
}

export default ForgotPassword
