import styled, { css } from 'styled-components'

const ErrorMessage = styled.div`
  ${({ theme, hasError }) => css`
    position: relative;
    color: ${theme.colors.accent.dangerLighten15};
    font-size: ${theme.fontSizes.small};
    opacity: 0;
    transition: opacity 0.25s;

    ${hasError &&
    css`
      opacity: 1;
      padding-left: ${theme.spacers.normal};
      margin-top: ${theme.spacers.normal};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: ${theme.colors.accent.dangerLighten15};
      }
    `};
  `}
`

export default ErrorMessage
