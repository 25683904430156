import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'

const Title = styled.h2`
  ${({ theme }) => css`
    position: relative;
    font-size: ${theme.fontSizes.large};
    margin-bottom: ${theme.spacers.normal};

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      width: 30px;
      background-color: ${theme.colors.main.primary};
      border-radius: 1.5rem;
    }

    ${down('small')} {
      text-align: center;
      font-size: ${theme.fontSizes.xlarge};
      margin: ${theme.spacers.large} 0;

      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  `}
`

export default Title
