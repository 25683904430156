import React from 'react'
import styled, { css } from 'styled-components'
import { down, up } from 'styled-breakpoints'

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    max-width: 996px;
    margin: 0 auto;
    height: 100%;

    ${down('small')} {
      position: relative;
      flex-direction: column;
      z-index: ${theme.zIndex.navbar};
    }

    ${up('medium')} {
      align-items: center;
      padding: ${theme.spacers.normal} 0;
    }
  `}
`

const LeftSide = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;

    ${down('small')} {
      background-color: ${theme.colors.accent.primaryDarken15};
      padding: ${theme.spacers.large};
    }

    ${up('medium')} {
      max-width: 45%;
      padding: 0 ${theme.spacers.normal};
    }
  `}
`

const RightSide = styled.div`
  ${({ theme }) => css`
    ${down('small')} {
      background-color: ${theme.colors.accent.primaryDarken15};
      margin-top: -30%;
    }
  `}
`

const FormWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacers.large};
    background-color: ${theme.colors.neutral.white};

    ${down('small')} {
      display: flow-root;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      padding-bottom: ${theme.spacers.large};
    }

    ${up('medium')} {
      padding: 0 ${theme.spacers.normal};
    }
  `}
`

function AuthLayout({ Image, children }) {
  return (
    <Wrapper>
      <LeftSide>
        <Image></Image>
      </LeftSide>
      <RightSide>
        <FormWrapper>{children}</FormWrapper>
      </RightSide>
    </Wrapper>
  )
}

export default AuthLayout
