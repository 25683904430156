import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import ErrorMessage from './ErrorMessage';

const Wrapper = styled.div`
  & + & {
    margin-top: ${({ theme }) => theme.spacers.normal};
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1200};
    margin-bottom: ${theme.spacers.xsmall};
  `}
`;

const InputField = styled.input`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacers.normal};
    font-size: ${theme.fontSizes.normal};
    border-radius: ${theme.round.normal};
    border: 1px solid ${theme.colors.neutral.gray400};
    outline: none;

    &::placeholder {
      color: ${theme.colors.neutral.gray600};
    }
  `}
`;

const Desc = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral.gray900};
  `}
`;

function Input({ error, inputRef, title, as = 'input', desc, ...props }) {
  const hasError = error && 'message' in error;

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <InputField {...props} as={as} ref={inputRef}></InputField>
      {desc && <Desc>{desc}</Desc>}
      <ErrorMessage hasError={hasError}>{error?.message}</ErrorMessage>
    </Wrapper>
  );
}

export default forwardRef((props, ref) => (
  <Input {...props} inputRef={ref}></Input>
));
