import styled, { css } from 'styled-components'

const SubmitButton = styled.button.attrs({ type: 'submit' })`
  ${({ theme }) => css`
    margin: ${theme.spacers.normal} 0;
    border: 0;
    border-radius: ${theme.round.normal};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    padding: ${theme.spacers.normal};
    font-size: ${theme.fontSizes.normal};
    outline: none;
    cursor: pointer;
  `}
`

export default SubmitButton
