export default {
  'auth/invalid-action-code':
    'รหัสระบุตัวตนไม่ถูกต้อง โปรดตรวจสอบข้อมูลอีกครั้ง',
  'auth/expired-action-code':
    'รหัสระบุตัวตนไม่ถูกต้อง โปรดตรวจสอบข้อมูลอีกครั้ง',
  'auth/user-not-found': 'ไม่พบผู้ใช้งานดังกล่าวในระบบ โปรดตรวจสอบอีกครั้ง',
  'auth/weak-password': 'กรุณาตั้งรหัสผ่านให้มีความซับซ้อนมากกว่านี้',
  'auth/wrong-password':
    'อีเมล์หรือรหัสผ่านไม่ถูกต้อง โปรดตรวจสอบข้อมูลอีกครั้ง',
  'auth/invalid-email':
    'อีเมล์หรือรหัสผ่านไม่ถูกต้อง โปรดตรวจสอบข้อมูลอีกครั้ง',
  'auth/email-already-in-use':
    'มีผู้ใช้งานอีเมล์ดังกล่าวแล้ว โปรดลองอีเมล์อื่น',
}
